<template>
	<div class="yuyue-app" >
		<div class="yuyue-header">
            <div class="selectDate">
                <a-button type="default"> < </a-button>
                <a-date-picker></a-date-picker>
                <a-button type="default"> > </a-button>
            </div>
            <div class="reserveStatus"><span class="reserveStatus_item" style="background: rgb(113, 61, 216); color: rgb(255, 255, 255);">
                   待审核 0
                </span><span class="reserveStatus_item" style="background: rgb(0, 161, 180); color: rgb(255, 255, 255);">
                   待到店 0
                </span><span class="reserveStatus_item" style="background: rgb(228, 56, 81); color: rgb(255, 255, 255);">
                   已到店 0
                </span><span class="reserveStatus_item" style="background: rgb(229, 113, 0); color: rgb(255, 255, 255);">
                   已开单 0
                </span><span class="reserveStatus_item" style="background: rgb(11, 180, 95); color: rgb(255, 255, 255);">
                   已完成 0
                </span><span class="reserveStatus_item" style="background: rgb(82, 134, 108); color: rgb(255, 255, 255);">
                   休息 
                </span><span class="reserveStatus_item" style="border: 1px solid rgb(237, 237, 237); color: rgb(140, 140, 140);">
                   空闲 
                </span></div>
        </div>
        <div class="dateReserveBpx">
            <a-table :columns="columns" :data-source="hour_list"  :scroll="{x:'calc(100%)',y: 500}"
            :pagination="false" :loading="false" rowKey="key" :bordered="true" :customRow="customRow" >

                <div slot="show_time" slot-scope="show_time,record,index">
                    <div  @click="showDetail(index,0)" style=" height: 75px;" :style="{'margin-top':index == 0?' -6px':' -24px'}">{{show_time }}</div>
                </div> 
                <div :slot="'show_time_'+index1" slot-scope="show_time_,record,index" v-for="(item1,index1) in (columns.length - 1)">
                    <div style="height:75px ;"  @click="showDetail(index,index1)" @mouseover="mouseoverFunc(index,index1)" :style="{
                                   'background-color': index < 3 ?'#F8F8F8':'',
                                   'cursor':  index < 3 ?'not-allowed':''
                                }">
                                 <div class="reserveBlock_item" v-if="index == 0 && index1 == 2"  @click="showDetail(index)"
                                    style="height: 150px; width: 150px; left: 100px; background-color: rgb(0, 161, 180);">
                                    <div class="user_name">A会员管理系统冯老师18665704582</div> 
                                    <div class="user_phone">18665704582</div> 
                                    <div class="user_project">海藻矽针换肤 挽救肌肤问题</div>
                                        <div class="record_status_top">线上</div>
                                </div>
                                
                            </div>
                                
                   
                </div> 
                <!-- v-if="_isRestDay() === 0 && timeDivision.systemTime<=(hour_list[hour_list.length-1] && hour_list[hour_list.length-1].show_time)" -->
                <div slot="append">
                    <!-- 当前时间分割线 -->
                    <div class="timeDivision" :style="{ top:timeDivision.scrollTop+'px',width:timeDivision.width - timeDivision.scrollLeft + 'px',left:timeDivision.scrollLeft + 25 + 'px' }">
                        <div class="currentTime">{{timeDivision.systemTime}}</div>
                        <div class="divisonLine"></div>
                    </div>
                </div>
               
              
            </a-table>
        </div>
        
    </div>        
</template>

<script>
    function formatDate(date, fmt) {
        if (typeof date == 'string') {
            return date;
        }

        //if (!fmt) fmt = "yyyy-MM-dd hh:mm:ss";
        if (!fmt) fmt = "yyyy-MM-dd";
        if (!date || date == null) return null;
        var o = {
            'M+': date.getMonth() + 1, // 月份
            'd+': date.getDate(), // 日
            'h+': date.getHours(), // 小时
            'm+': date.getMinutes(), // 分
            's+': date.getSeconds(), // 秒
            'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
            'S': date.getMilliseconds() // 毫秒
        }
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
        for (var k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
        return fmt
    }

    let currentDate = formatDate(new Date());
	export default{
		components: {
			
		},
		data(){
			return{
				loading: false,
				msgVisible:false,
				member_id:0,
				msg:"",
				search: {
					mobile: "",
				},
				addMemberVisible: false,
                columns:[
                    // { title: '', width: 100, dataIndex: 'name', key: 'name', fixed: 'left' },
                   
                ],
                datas:[],
                hour_list:[],
                // 间隔线需要的信息
                timeDivision:{
                    scrollTop:0,// 与顶部的距离
                    systemTime:"", // 当前时间
                    scrollLeft:0,// 滚动条滑动的距离
                    width:0 // 宽度
                },
                currentDate:'',

			}
		},
		mounted() {
            this.currentDate = formatDate(new Date());
            const columns = [];
            for (var i = 0; i < 20; i++) {
                columns.push({
                    align: 'center',
                    key: i,
                    title: i==0?'':`列${i}`,
                    dataIndex: i==0?'show_time':'show_time_'+i,
                    fixed: i==0?'left':false,
                    width:i==0?100:150,
                    scopedSlots: {
                        customRender:  i==0?'show_time':'show_time_'+i,
                    },
                    customCell:function(record, index){
                        return ;
                        //console.log('record',index)
                        return {
                            on:{
                                click: (event) => {
                                    console.log('event.target',event.target.cellIndex)
                                    let tdList = event.target.childNodes
                                    let indexNum = index+1
                                    console.log('indexNum',indexNum)
                                    let tr=document.getElementsByTagName("tr")[indexNum]
                                    let trList=tr.childNodes;
                                    // 获取节点
                                    for(let i=1;i<trList.length;i++){
                                    let td =trList[i]
                                   
                                    let typ=document.createAttribute("class")
                                    typ.nodeValue="mouseentered-"+i
                                    td.attributes.setNamedItem(typ)
                                    }
                                    console.log('单元格click',record, index,event);
                                },       // 点击行
                                dblclick: (event) => {},
                                contextmenu: (event) => {},
                                mouseenter: (event) => {
                                    //console.log('单元格clickmouseenter',record, rowIndex,event);
                                },  // 鼠标移入行
                                mouseleave: (event) => {} 
                            }
                        }
                        //console.log('record, rowIndex',record, rowIndex)
                    }
                    // customRender: (text, record, index) => {
                    //     //record.tr_index = index;
                    //     //return record;
                    // }
                });
            }
            columns.push({
                    align: '',
                    key: 21,
                   
                    dataIndex: 'append',
                    
                    scopedSlots: {
                        customRender: 'append',
                    },
                    // customRender: (text, record, index) => {
                    //     //record.tr_index = index;
                    //     //return record;
                    // }
                });
            console.log('columns',columns)
            this.columns = columns;
			// const data = [];
            // for (var i = 0; i < 100; i++) {
            // data.push({
            //         key: i,
            //         address: `${i}`,
            //     });
            // }
            // this.datas = data;
            //处理预约时间
            var list = [
                {
                    end_time: "08:00",
                    end_time_type: "1",
                    start_time: "08:00",
                }
            ]
            let { start_time,end_time } = this.lookForTime(list);
            this.loopTime(start_time,end_time,30,list);
            console.log(this.hour_list)

            let startTime = this.hour_list[0].show_time;
            let systemTime = new Date().getHours()+':'+(new Date().getMinutes() < 10?'0'+ new Date().getMinutes():new Date().getMinutes());
            let borderScrollTop = Math.floor(this.getTimeInterval(startTime,systemTime,'2') / 30);
            this.timeDivision.scrollTop = 100;//(this.getTimeInterval(startTime,systemTime,'2') * 2.5)+borderScrollTop;
            this.timeDivision.systemTime = systemTime;
            this.timeDivision.width = '100vw';
		},
		methods:{
            showDetail(index,index1){
                console.log('showDetail',index,index1)
            },
            customRow(e1,e2)
            {
                return ;
                return {
                    on:{
                        click: (event) => {
                            console.log(event.target.offsetParent )
                            console.log('行click',e1,e2,event);
                        },       // 点击行
                        dblclick: (event) => {},
                        contextmenu: (event) => {},
                        mouseenter: (event) => {
                            //console.log('行mouseenter',e1,e2,event);
                        },  // 鼠标移入行
                        mouseleave: (event) => {} 
                    }
                }
                
            },
			mouseoverFunc(index,index1){
                console.log('mouseoverFunc',index,index1)
            },
            // 日期切换
            changeToDay(instruct){ 
              if(instruct === 'last') { this.currentDate = formatDate(new Date().setTime(new Date(this.currentDate).getTime()-86400000)) }
              else if(instruct === 'next') { this.currentDate = formatDate(new Date().setTime(new Date(this.currentDate).getTime()+86400000)) }
             
            },
            _isRestDay(){
                let isDay = 0;
                // 当天时间戳
                let currentTime = new Date(currentDate).getTime();
                // 当前选中时间戳
                let selectTime = new Date(this.currentDate).getTime();
                // 时间差
                let timeDifference = selectTime - currentTime;
                if(timeDifference>0) { isDay = 1 }
                else if(timeDifference<0) { isDay = 2 }
                return isDay
            },
            // 获取时间间隔
            getTimeInterval(time_date,time_end_date,end_time_type){
                let minute = 0;
                // 双比较得出分钟数
                let startHour = +time_date.split(':')[0];
                let startMinute = +time_date.split(':')[1];
                let endHour = +time_end_date.split(':')[0];
                // 如果结束是次日,结束时间需要加24
                if(end_time_type === '1'){ endHour += 24 }
                let endMinute = +time_end_date.split(':')[1];
                let differHour = endHour - startHour;
                minute = differHour * 60;
                if(startMinute>endMinute){
                    minute -= startMinute -endMinute;
                }else{
                    minute += endMinute  -startMinute;
                }
                return minute;
            },
            // 找出数组中最小的开始和时间和最大的结束时间
            lookForTime(list = []){
                let minTime = +(list[0].start_time.replace(':',''));
                let maxTime =
                list[0].end_time_type === '1'?
                +(list[0].end_time.replace(':',''))+2400:
                +(list[0].end_time.replace(':',''));
                list.forEach(t=>{
                    let start_time = +(t.start_time.replace(':',''));
                    let end_time = +(t.end_time.replace(':',''));
                    if(t.end_time_type === '1'){  end_time = end_time + 2400 }
                    if(start_time<minTime){
                        minTime = start_time;
                    }
                    if(end_time>maxTime){
                        maxTime = end_time;
                    }
                })
                return { start_time:minTime,end_time:maxTime }
            },
            loopTime(start_time,end_time,reserve_time_interval,list){
                let startTimeStr = start_time === 0?'0000':
                (''+start_time).length === 1?'000'+start_time:
                (''+start_time).length<3?'00'+start_time:
                (''+start_time).length<4?'0'+start_time:''+start_time;
                let timeText = startTimeStr.substr(0,2)+':'+ startTimeStr.substr(2,2);
                this.hour_list.push({
                    show_time:
                    start_time >=2400?
                    '次日'+ ((+startTimeStr.substr(0,2) -24)<10?'0'+(+startTimeStr.substr(0,2) -24):startTimeStr.substr(0,2)) +':'+ startTimeStr.substr(2,2)
                    :startTimeStr.substr(0,2)+':'+ startTimeStr.substr(2,2),
                    timeValue:start_time,
                    //disabled: this.checkTime(list, timeText),
                    asyncTime:0
                })
                let hour = + startTimeStr.substr(0,2);
                let minute = + startTimeStr.substr(2,2);
                minute = minute + reserve_time_interval;
                if(minute>=60){
                    hour = hour + Math.floor(minute / 60);
                    minute = minute % 60;
                }
                if(hour<10) hour = '0'+hour;
                if(minute<10) minute = '0'+minute;
                start_time = +((''+hour)+(''+minute))
                if(start_time <= end_time) { this.loopTime(start_time,end_time,reserve_time_interval,list) }
                else { return }  
            },
		},
		destroyed() {
			
		}
	}
</script>

<style>
.dateReserveBpx .ant-table-tbody > tr > td:first-child{
    border-bottom: none;
}
.dateReserveBpx .ant-table-thead > tr > th, .dateReserveBpx .ant-table-tbody > tr > td {
    min-height: 75px;
    padding:0;
    overflow-wrap: break-word;
}
.dateReserveBpx .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.dateReserveBpx .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, 
.dateReserveBpx .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.dateReserveBpx  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: none;
}
.dateReserveBpx .ant-table-thead > tr > th{
    height: 46px;
    background: #ffffff;
    font-weight: bold;

}
.dateReserveBpx .timeDivision {
    display: flex;
    align-items: center;
    position: absolute;
    top: 38px;
    left: 25px;
    width: 100vw;
    z-index: 4;
}
.dateReserveBpx .timeDivision .currentTime {
    position: absolute;
    z-index: 4;
    width: 42px;
    height: 16px;
    line-height: 16px;
    background: #FF6282;
    border-radius: 8px;
    color: #fff;
    font-size: 12px;
    text-align: center;
}
.dateReserveBpx .timeDivision .currentTime::after {
    position: absolute;
    content: "";
    top: 8px;
    right: -23px;
    height: 1px;
    width: 23px;
    background-color: #e43851;
}
.dateReserveBpx .timeDivision .divisonLine {
    flex: 1;
    height: 1px;
    background-color: #e43851;
}
 .dateReserveBpx .reserveBlock_item {
    position: absolute;
    width: 100%;
    height: 75px;
    padding: 5px;
    text-align: left;
    border-radius: 0 8px 8px 0;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
}
.dateReserveBpx  .reserveBlock_item .user_name, .dateReserveBpx  .reserveBlock_item .user_phone, .dateReserveBpx  .reserveBlock_item .user_project {
    white-space: nowrap;
    margin-bottom: 5px;
}
.dateReserveBpx  .reserveBlock_item .record_status_top {
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 23px;
    text-align: center;
    background-color: #008127;
    color: #fff;
    border-radius: 0 8px 0 8px;
    font-size: 11px;
}
    .table-yuyue {
        font-size: 16px;
        color: #000000;
        border-spacing: 0;
    }
    .table-row > td {
       
        white-space: nowrap;
        border-right: 1px solid #ebeef5;
        border-bottom: 1px solid #ebeef5;
    }
     /* .table-row:first-child > td {
       border-top: 1px solid #ebeef5; 
    }*/
    .table-row > td:first-child {
        /* border-left: 1px solid #c1c1c1;
        border-left:1px solid #ebeef5; */
        vertical-align:middle;
    }
    .table-container {
        width: 100%;
        height: 100%;
        overflow: auto;
    }
 
    /* 首列固定 */
 
    .table-container thead tr > td:first-child,
    .table-container tbody tr > td:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        background-color: #fdfeff;
        border-bottom: none;
    }
 
    /* 表头固定 */
    .table-container thead tr > td {
        position: sticky;
        top: 0;
        z-index: 2;
    }
 
 
    /* 表头首列强制最顶层 */
    .table-container thead tr > td:first-child {
        z-index: 3;
    }
 
    .flex-row {
        margin: 0 -1px;
        padding:1px;
    }
    .flex-row > div:first-child {
        margin-right: 1px;
    }
 

.yuyue-app{
    padding: 10px 10px 0 10px;
    height: calc(100vh - 70px);
    background: #ffffff;
}	
.yuyue-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.yuyue-header .selectDate {
    position: relative;
    color: #000;
    background: #ffffff;
    border-radius: 5px;
    margin: 0 50px;
}
.yuyue-header .reserveStatus {
    display: flex;
}
.yuyue-header .reserveStatus .reserveStatus_item {
    width: 72px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 12px;
    margin-right: 13px;
    cursor: pointer;
}



</style>
